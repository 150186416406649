import { useDisclosure } from "@chakra-ui/react"
import queryString from "query-string"
import React, { useContext } from "react"
import { useJwt } from "react-jwt"
import KioskEditorHoc from "../components/kioskEditor/kioskEditorHoc"
import SimpleLayout from "../components/simpleLayout"
import UserContext from "../context/userContext"
import useHasKioskAccess from "../hooks/useHasKioskAccess"
import Login from "../pages/login"
export default function Edit({ data, pageContext, location }: any) {
  const { tags } = pageContext

  const overlayControl = useDisclosure()
  const { state } = useContext(UserContext)

  const jwt = useJwt(state?.jwt ?? "")
  const parsed = queryString.parse(location.search)
  const id = parsed?.id ?? jwt?.decodedToken?.kioskId
  const hasAccess = useHasKioskAccess(id)

  if (!hasAccess) {
    return <Login location={location} noRedirect={true} />
  }

  return (
    <SimpleLayout
      title="Stalletjes langs de weg - Alle kraampjes aan de straat"
      location={location}
      width="1000px"
    >
      <KioskEditorHoc
        kioskId={id}
        products={tags}
        overlayControl={overlayControl}
      />
    </SimpleLayout>
  )
}
